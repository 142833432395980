/* ==========================================================================
   Variabeles
   ========================================================================== */

$c-base__03: #0D2B35;
$c-base__02: #163541;
$c-base__01: #5C6E74;
$c-base__00: #697B82;
$c-base__0: #869395;
$c-base__1: #96A0A0;
$c-base__2: #EDE7D6;
$c-base__3: #FCF5E4;

$c-accent__blue: #0067FB;
$c-accent__darkblue: #0029F9;
$c-accent__green: #8BE367;

$c-accent__purple: #6a5acd;

// Breakpoints
$bp__sm: 45rem; // 450px

// Misc
$m-border: 1px solid $c-base__2;

$c-background__cream: #fff8e7;