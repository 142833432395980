/* ==========================================================================
   Layout
   ========================================================================== */

.u-container {
    max-width: 72rem; // 620px
    margin-right: auto;
    margin-left: auto;
    padding-top: 10rem; // 100px
    padding-right: 1rem; // 10px
    padding-left: 1rem; // 10px
}

