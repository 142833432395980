/* ==========================================================================
   Reset
   ========================================================================== */

/* Reset Modified from Normalize.css */

/* Base Reset */

* {
    margin: 0;
    padding: 0;
}

*, *:before, *:after { box-sizing: inherit; }


html {
    min-height: 100%;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-size: 62.5%; // Make it easy to calculate rems to px
}

body {
    @include ff--sans-serif(400);
    -webkit-font-smoothing: antialiased;
    background-color: $c-background__cream;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    color: $c-base__02;
}


/* Media */
audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

/* Images */

[hidden], template { display: none; }

img {
    max-width: 100%;
    font-style: italic;
    vertical-align: middle;
    border: 0;
}

svg:not(:root) { overflow: hidden; }

svg {
    pointer-events: none;
    max-height: 100%
}

.center {
  text-align: center; 
}

/* Links */

a {
    color: $c-accent__purple;
    background-color: transparent;
    text-decoration: none;
    
    &:hover {
      color: $c-accent__purple !important;
      border-bottom: 1px solid $c-accent__purple !important;
      text-decoration: none !important;
      transition: border 0.15s ease-in;
    }

}

/* Override hover animation with no-hov class */
a.no-hov {
	&:after {
		content: none
	}
	
	&:hover {
		color: $c-accent__darkblue
	}
}

a.nav {
  padding: 10px 35px;  
  overflow:hidden;
}
a.nav:before {
    font-family: FontAwesome;
    content:"\f07a";
    position: absolute;
    top: 11px;
    left: -30px;
    transition: all 200ms ease;
}

abbr[title] { border-bottom: 1px dotted; }
b, strong   { font-weight: bold; }
i, em       { font-weight: italic; }

/* Content */

figure {
    margin: 0;
}


hr {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    width: 100%;
    height: 1px;
    border: 0;
    background: #EFF1F3;
}

/* Code Blocks */

pre { overflow: auto; }

code, pre {

}
small {
        color: gray;
}

.mbtablestyle {
    border-collapse: collapse;

    > table, td, th {
        border: 1px solid black;
        padding-right:10px;
        padding-left:10px;
        padding-top:5px;
        padding-bottom:5px;
        }
}
