/* ==========================================================================
   Tip
   ========================================================================== */

.c-tags {
  list-style: none;
  margin-bottom: 2.5rem;
  overflow: hidden; 
  padding: 0;
}

.c-tags li {
  float: right;
  padding: 10px;
}

.c-tag {
  color: $c-base__01;
  line-height: 1.5;
  font-size: 1.5rem;
}
